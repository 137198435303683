import { DevTool } from "@hookform/devtools";
import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { debounce } from "../../utils/functions";
import styles from "./EqxForm.module.scss";

const EqxForm = React.forwardRef(
  (
    {
      children,
      className,
      debounceTime,
      defaultValues,
      onCTA,
      onFormBlur,
      onFormChange,
      onSubmit,
      onSubmitError,
      onValidityChange,
      schema = {},
      values,
    },
    ref
  ) => {
    const formMethods = useForm({
      defaultValues,
      mode: "onBlur",
      resolver: zodResolver(schema),
      values,
    });
    const { control, formState, getValues, handleSubmit, reset } = formMethods;
    const { isValid } = formState;
    const submitForm = debounce(
      handleSubmit(onSubmit, onSubmitError),
      debounceTime
    );

    const handleChildClicked = (e) => {
      const clickedElement = e.target;
      const { type } = clickedElement;
      const isCTA = clickedElement.getAttribute("data-is-cta") === "true";
      const isSelect = clickedElement.getAttribute("role") === "option";

      if (type !== "submit" && !isCTA && !isSelect) {
        return;
      }

      e.preventDefault();

      if (isCTA && onCTA) {
        onCTA(getValues(), clickedElement.getAttribute("value"));

        return;
      }

      if (isSelect) {
        if (onFormChange) {
          onFormChange(getValues());
        }

        return;
      }

      submitForm();
    };

    const resetForm = () => {
      reset();
    };

    const handleKeyUp = useCallback(
      (e) => {
        onFormChange && onFormChange(getValues());

        if (e.key !== "Enter") {
          return;
        }

        e.preventDefault();

        submitForm();
      },
      [getValues, onFormChange, submitForm]
    );

    const handleOnBlur = () => {
      if (onFormBlur) {
        onFormBlur(getValues());
      }
    };

    useEffect(() => {
      onValidityChange(isValid);
    }, [isValid]); // eslint-disable-line react-hooks/exhaustive-deps -- WEB-2739

    // Use useImperativeHandle to expose the child method to the parent component
    React.useImperativeHandle(ref, () => ({
      resetForm,
    }));

    return (
      <FormProvider {...formMethods}>
        <form
          className={classNames(styles.eqxForm, className)}
          data-is="EqxForm"
          onBlur={handleOnBlur}
          onClick={handleChildClicked}
          onKeyUp={handleKeyUp}
        >
          {children}
        </form>

        <DevTool control={control} />
      </FormProvider>
    );
  }
);

EqxForm.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  debounceTime: PropTypes.number,
  defaultValues: PropTypes.object,
  onCTA: PropTypes.func,
  onChange: PropTypes.func,
  onFormBlur: PropTypes.func,
  onFormChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func,
  onValidityChange: PropTypes.func,
  schema: PropTypes.object,
  values: PropTypes.object,
};

EqxForm.displayName = "EqxForm";

EqxForm.defaultValues = {
  defaultValues: {},
  values: {},
};

export default EqxForm;
