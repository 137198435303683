// this file is generated by running scripts/clubData.js

/* eslint-disable sort-keys */

const timeZones = {
  america: {
    chicago: "America/Chicago",
    denver: "America/Denver",
    losAngeles: "America/Los_Angeles",
    newYork: "America/New_York",
    toronto: "America/Toronto",
    vancouver: "America/Vancouver",
  },
  europe: { london: "Europe/London" },
};

export const ANTHEM_ROW = {
  id: 254,
  name: "Equinox Anthem Row",
  path: "/clubs/washington-dc/anthemrow",
  address: "800 K St NW, Suite 90",
  city: "Washington",
  state: "DC",
  zip: "20001",
  country: "US",
  phoneNumber: "(202) 741-0015",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/14HvSE56SAp27b1cUrOY2Y/55f155817ef65ccfb4765b91da112a29/Equinox_ClubPage_OtherLocations_AnthemRow_2300x1533-min.jpg",
  slug: "ANTHEM_ROW",
  spa: { phoneNumber: "(202) 741-0015" },
  lat: 38.901702,
  long: -77.022731,
  region: "Washington DC",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const ARMONK = {
  id: 149,
  name: "Equinox Armonk",
  path: "/clubs/new-york/westchester/armonk",
  address: "99 Business Park Dr",
  city: "Armonk",
  state: "NY",
  zip: "10504",
  country: "US",
  phoneNumber: "(914) 219-1601",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/4ygogdnEfFXwizCVR65KJL/357673badd7aa37c869638848d99a729/Equinox_ClubPage_OtherLocations_Armonk_2300x1533-min.jpg",
  slug: "ARMONK",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 41.1207391,
  long: -73.7086067,
  region: "New York/Westchester",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const AUSTIN = {
  id: 456,
  name: "Equinox Austin",
  path: "/clubs/texas/austin",
  address: "1007 South Congress Ave, Suite 100",
  city: "Austin",
  state: "TX",
  zip: "78704",
  country: "US",
  phoneNumber: "(512) 652-3321",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/1Ie2hPyG8O1vcyp1GVVsek/c4fffd1e13886235788122b5c20159a5/Equinox_ClubPage_OtherLocations_Austin_2300x1533-min.jpg",
  slug: "AUSTIN",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 30.25445,
  long: -97.74753,
  region: "Texas",
  type: "Regular",
  timeZone: timeZones.america.chicago,
};

export const AVENTURA = {
  id: 303,
  name: "Equinox Aventura",
  path: "/clubs/florida/aventura",
  address: "19501 Biscayne Blvd. (Aventura Mall)",
  city: "Aventura",
  state: "FL",
  zip: "33180",
  country: "US",
  phoneNumber: "(305) 935-9514",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/4ywsL2jWfUprQPIFMBRcfn/e747bfd7c657e65af69607de87301b95/Equinox_ClubPage_OtherLocations_Aventura_2300x1533.jpg",
  slug: "AVENTURA",
  spa: { phoneNumber: "(305) 935-9514" },
  lat: 25.9573136,
  long: -80.1432508,
  region: "Florida",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const BAY_STREET = {
  id: 852,
  name: "Equinox Bay Street",
  path: "/clubs/canada/toronto/bayst",
  address: "199 Bay St, Commerce Court West",
  city: "Toronto",
  state: "ON",
  zip: "M5L 1E2",
  country: "CA",
  phoneNumber: "(647) 497-5158",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/3rj3UIdbFYfrM9wKQthV5/a6e5dcbae2368002e6dbaac3189f9da7/Equinox_ClubPage_OtherLocations_BaySt_2300x1533-min.jpg",
  slug: "BAY_STREET",
  spa: { phoneNumber: "(647) 497-5158" },
  lat: 43.6482347,
  long: -79.3795255,
  region: "Canada/Toronto",
  type: "Regular",
  timeZone: timeZones.america.toronto,
};

export const BEALE_STREET = {
  id: 727,
  name: "Equinox Beale Street",
  path: "/clubs/northern-california/bealest",
  address: "320 Mission St",
  city: "San Francisco",
  state: "CA",
  zip: "94105",
  country: "US",
  phoneNumber: "(415) 228-0024",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/1h4USaUSLUcZPu6yRdaSht/a6e154f357b4d03832e7e4f43879dc96/Equinox_ClubPage_OtherLocations_Beale_2300x1533-min.jpg",
  slug: "BEALE_STREET",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 37.791728,
  long: -122.39668,
  region: "Northern California",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const BERKELEY = {
  id: 719,
  name: "Equinox Berkeley",
  path: "/clubs/northern-california/berkeley",
  address: "2600 Shattuck Ave",
  city: "Berkeley",
  state: "CA",
  zip: "94704",
  country: "US",
  phoneNumber: "(510) 982-5076",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/1IgeD41vUlBPzUFqfk4gPM/ca85aec760bb82abe72ae8ce86343ebf/Equinox_ClubPage_OtherLocations_Berkley_2300x1533-min.jpg",
  slug: "BERKELEY",
  spa: { phoneNumber: "(510) 982-5076" },
  lat: 37.861629,
  long: -122.267677,
  region: "Northern California",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const BETHESDA = {
  id: 252,
  name: "Equinox Bethesda",
  path: "/clubs/washington-dc/bethesda",
  address: "4905 Elm St",
  city: "Bethesda",
  state: "MD",
  zip: "20814",
  country: "US",
  phoneNumber: "(301) 652-1078",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/4g0DtLSbtQ3Nx3yxObWoEH/b45aadd9d0d68d92a6d342899328f658/Equinox_ClubPage_OtherLocations_Bethesda_2300x1533-min.jpg",
  slug: "BETHESDA",
  spa: { phoneNumber: "(301) 652-1078" },
  lat: 38.9821841,
  long: -77.0963788,
  region: "Washington DC",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const BEVERLY_HILLS = {
  id: 712,
  name: "Equinox Beverly Hills",
  path: "/clubs/southern-california/los-angeles/beverlyhills",
  address: "9601 Wilshire Blvd",
  city: "Beverly Hills",
  state: "CA",
  zip: "90210",
  country: "US",
  phoneNumber: "(310) 888-8100",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/3yeiTun25TxeKxi5wM8Qng/20d8b2ba68cd70b63d6fbb9c4939fca8/Equinox_ClubPage_OtherLocations_BeverlyHills_2300x1533-min.jpg",
  slug: "BEVERLY_HILLS",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 34.0674084,
  long: -118.4033688,
  region: "Southern California/Los Angeles",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const BISHOPSGATE = {
  id: 875,
  name: "Equinox Bishopsgate",
  path: "/clubs/london/bishopsgate",
  address: "8 Clerk's Pl",
  city: "London",
  state: "",
  zip: "EC3A 8AQ",
  country: "UK",
  phoneNumber: "+44 (0) 203 057 4497",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/1FjHj152AmrNOcbzt7dSo2/d61407a041af89bb4d0847c4c8e0fbfe/Equinox_ClubPage_OtherLocations_BishopsGate_2300x1533-min.jpg",
  slug: "BISHOPSGATE",
  spa: { phoneNumber: "+44 (0) 203 057 4497" },
  lat: 51.515666,
  long: -0.08104,
  region: "London",
  type: "Regular",
  timeZone: timeZones.europe.london,
};

export const BLOOMFIELD_HILLS = {
  id: 420,
  name: "Equinox Bloomfield Hills",
  path: "/clubs/michigan/bloomfieldhills",
  address: "4065 West Maple Rd",
  city: "Bloomfield Hills",
  state: "MI",
  zip: "48301",
  country: "US",
  phoneNumber: "(248) 785-3655",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6fUTzSHzkcskycUFQ1Akwv/1304fc60dd56087dcc45629694eaeb51/Equinox_ClubPage_OtherLocations_BloomfieldHills_2300x1533.jpg",
  slug: "BLOOMFIELD_HILLS",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 42.543885,
  long: -83.283294,
  region: "Michigan",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const BOND_STREET = {
  id: 135,
  name: "Equinox Bond Street",
  path: "/clubs/new-york/downtown/bondst",
  address: "0 Bond St",
  city: "New York",
  state: "NY",
  zip: "10012",
  country: "US",
  phoneNumber: "(212) 419-3273",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/62cE1JtKrMP6CWxHAzr5Th/fa5878b64bcf5d807e5942f894430b92/Equinox_ClubPage_OtherLocations_Bond_2300x1533-min.jpg",
  slug: "BOND_STREET",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 40.727238,
  long: -73.9947959,
  region: "New York/Downtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const BRICKELL = {
  id: 304,
  name: "Equinox Brickell",
  path: "/clubs/florida/brickell",
  address: "1441 Brickell Ave #4",
  city: "Miami",
  state: "FL",
  zip: "33131",
  country: "US",
  phoneNumber: "(305) 533-1199",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6LD0LJC4bDuqyCOCoGUxQg/915b5632e5abe95bfedbeffdae90f9fd/Equinox_ClubPage_OtherLocations_Brickell_2300x1533.jpg",
  slug: "BRICKELL",
  spa: { phoneNumber: "(305) 533-1199" },
  lat: 25.7589318,
  long: -80.1922286,
  region: "Florida",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const BRICKELL_HEIGHTS = {
  id: 305,
  name: "Equinox Brickell Heights",
  path: "/clubs/florida/brickellheights",
  address: "25 SW 9th St",
  city: "Miami",
  state: "FL",
  zip: "33131",
  country: "US",
  phoneNumber: "(305) 350-2278",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/74fU04pMO1svlrGpO0eype/28eff1ffcdfd0bfb32555046e8f7b13e/Equinox_ClubPage_OtherLocations_BrickellHeights_2300x1533-min.jpg",
  slug: "BRICKELL_HEIGHTS",
  spa: { phoneNumber: "(305) 350-2278" },
  lat: 25.765635,
  long: -80.193603,
  region: "Florida",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const BROOKFIELD_PLACE = {
  id: 128,
  name: "Equinox Brookfield Place",
  path: "/clubs/new-york/downtown/brookfieldplace",
  address: "225 Liberty St",
  city: "New York",
  state: "NY",
  zip: "10281",
  country: "US",
  phoneNumber: "(646) 630-7573",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/1e8OUcY6MkqnTZsed7B2QE/8ff1429d53bbe3805b656050d94db282/Equinox_ClubPage_OtherLocations_BrookfieldPlace2300x1533-min.jpg",
  slug: "BROOKFIELD_PLACE",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 40.7119835,
  long: -74.0152643,
  region: "New York/Downtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const BROOKLYN_HEIGHTS = {
  id: 130,
  name: "Equinox Brooklyn Heights",
  path: "/clubs/new-york/brooklyn/brooklynheights",
  address: "194 Joralemon St",
  city: "Brooklyn",
  state: "NY",
  zip: "11201",
  country: "US",
  phoneNumber: "(718) 522-7533",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/5WFyrh7vYT8eaxOC3vxYxe/80f2b5b9102cecdc6631ad1f261d6bc5/Equinox_ClubPage_Locations_BrooklynHeights_2300x1533-min.jpg",
  slug: "BROOKLYN_HEIGHTS",
  spa: { phoneNumber: "(718) 522-7533" },
  lat: 40.6924762,
  long: -73.9915209,
  region: "New York/Brooklyn",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const BRYANT_PARK = {
  id: 127,
  name: "Equinox Bryant Park",
  path: "/clubs/new-york/midtown/bryantpark",
  address: "129 West 41st St",
  city: "New York",
  state: "NY",
  zip: "10036",
  country: "US",
  phoneNumber: "(212) 299-0562",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6SN9XohFIeKPEUBnvLHwn0/674c9fccfccfce6b3bc24a6bec36d506/Equinox_ClubPage_OtherLocations_BryantPark_2300x1533-min.jpg",
  slug: "BRYANT_PARK",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 40.7548596,
  long: -73.9854492,
  region: "New York/Midtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const CENTURY_CITY = {
  id: 750,
  name: "Equinox Century City",
  path: "/clubs/southern-california/los-angeles/centurycity",
  address: "10250 Santa Monica Blvd",
  city: "Century City",
  state: "CA",
  zip: "90067",
  country: "US",
  phoneNumber: "(310) 552-0420",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/4gBELVGyu0ZdABwzcXJnyS/4799282f838429707c7a8329bab35edf/Equinox_ClubPage_Spaces_DT_CenturyCity_3200x2133_____1-min.jpg",
  slug: "CENTURY_CITY",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 34.058399,
  long: -118.419007,
  region: "Southern California/Los Angeles",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const CHESTNUT_HILL = {
  id: 203,
  name: "Equinox Chestnut Hill",
  path: "/clubs/boston/chestnuthill",
  address: "200 Boylston St",
  city: "Chestnut Hill",
  state: "MA",
  zip: "02467",
  country: "US",
  phoneNumber: "(617) 531-7077",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/5spAdpKmcVgxAg21ZdpxUt/8149ca598fa18bcdef10d6502a65000e/Equinox_ClubPage_OtherLocations_ChestnutHill_2300x1533-min.jpg",
  slug: "CHESTNUT_HILL",
  spa: { phoneNumber: "(617) 375-8520" },
  lat: 42.320089,
  long: -71.17594,
  region: "Boston",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const COLUMBUS_CIRCLE = {
  id: 113,
  name: "Equinox Columbus Circle",
  path: "/clubs/new-york/uptown/columbuscircle",
  address: "Time Warner Center (60th and Broadway)",
  city: "New York",
  state: "NY",
  zip: "10019",
  country: "US",
  phoneNumber: "(212) 871-0425",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/2O8PDTTIv1h5SWwHtPQVu1/e6aec0fe2a1b8217090aaf6666904952/Equinox_ClubPage_OtherLocations_ColumbusCircle_2300x1533-min.jpg",
  slug: "COLUMBUS_CIRCLE",
  spa: { phoneNumber: "(212) 871-0425" },
  lat: 40.7686608,
  long: -73.9826273,
  region: "New York/Uptown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const CORAL_GABLES = {
  id: 302,
  name: "Equinox Coral Gables",
  path: "/clubs/florida/coralgables",
  address: "The Village of Merrick Park at 370 San Lorenzo Ave",
  city: "Coral Gables",
  state: "FL",
  zip: "33146",
  country: "US",
  phoneNumber: "(786) 497-8200",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/3A3HOsGzbtljF8TXP85lvi/76926e35562d9f5d78783d4a05dffe9c/Equinox_ClubPage_OtherLocations_CoralGables_2300x1533.jpg",
  slug: "CORAL_GABLES",
  spa: { phoneNumber: "(786) 497-8200" },
  lat: 25.7319739,
  long: -80.2606335,
  region: "Florida",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const CULVER_CITY = {
  id: 752,
  name: "Equinox Culver City",
  path: "/clubs/southern-california/los-angeles/culvercity",
  address: "10000 W. Washington Blvd",
  city: "Culver City",
  state: "CA",
  zip: "90232",
  country: "US",
  phoneNumber: "(310) 736-3917",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/4Mj45JifvFtQHE3wcwovFf/41a70d8c9daa43d6fd846d6c6ec207e2/Equinox_ClubPage_OtherLocations_CulverCity_2300x1533-min.jpg",
  slug: "CULVER_CITY",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 34.020571,
  long: -118.398036,
  region: "Southern California/Los Angeles",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const DARIEN = {
  id: 141,
  name: "Equinox Darien",
  path: "/clubs/connecticut/darien",
  address: "72 Heights Rd",
  city: "Darien",
  state: "CT",
  zip: "06820",
  country: "US",
  phoneNumber: "(203) 655-2300",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/5XwWUC8ZquTtH1SEVcFk3I/9910c530e978df4ab3cfdb09d3ad56e7/Equinox_ClubPage_Locations_Darien_2300x1533.jpg",
  slug: "DARIEN",
  spa: { phoneNumber: "(203) 655-2300" },
  lat: 41.0700644,
  long: -73.4925335,
  region: "Connecticut",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const DARTMOUTH_STREET = {
  id: 201,
  name: "Equinox Dartmouth Street",
  path: "/clubs/boston/dartmouth",
  address: "131 Dartmouth St",
  city: "Boston",
  state: "MA",
  zip: "02116",
  country: "US",
  phoneNumber: "(617) 578-8918",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/4ml6xxV8GwB0feIRYTI1bb/84ffce72664bbd6b7a3905fa478a13ed/Equinox_ClubPage_Locations_DartmouthSt_2300x1533-min.jpg",
  slug: "DARTMOUTH_STREET",
  spa: { phoneNumber: "(617) 375-8520" },
  lat: 42.3469738,
  long: -71.0750967,
  region: "Boston",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const DOMINO = {
  id: 161,
  name: "Equinox Domino",
  path: "/clubs/new-york/brooklyn/domino",
  address: "266 Kent Ave",
  city: "Brooklyn",
  state: "NY",
  zip: "11249",
  country: "US",
  phoneNumber: "(718) 522-5703",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/3DKAcpqIchCkZEsav7LwgK/380d7c7092ff02b4bee6cbbed1c857de/Equinox_ClubPage_OtherLocations_Williamsburg_2300x1533-min.jpg",
  slug: "DOMINO",
  spa: { phoneNumber: "(347) 272-1000" },
  lat: 40.71592,
  long: -73.96638,
  region: "New York/Brooklyn",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const DOWNTOWN_LA = {
  id: 715,
  name: "Equinox Downtown LA",
  path: "/clubs/southern-california/los-angeles/downtownla",
  address: "444 Flower St",
  city: "Los Angeles",
  state: "CA",
  zip: "90071",
  country: "US",
  phoneNumber: "(213) 330-3999",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/MKEbohKVknOuixqs3K3a0/17eb943e804613ca014814bc6ded1c62/Equinox_ClubPage_OtherLocations_DowntownLA_2300x1533-min.jpg",
  slug: "DOWNTOWN_LA",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 34.0516063,
  long: -118.2551154,
  region: "Southern California/Los Angeles",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const DUMBO = {
  id: 134,
  name: "Equinox DUMBO",
  path: "/clubs/new-york/brooklyn/dumbo",
  address: "117 Front St",
  city: "Brooklyn",
  state: "NY",
  zip: "11201",
  country: "US",
  phoneNumber: "(347) 435-2341",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6BZlQfeoSO91qcgz9O4uEN/8bdb250270323fbe31e7f6dca201e9c2/Equinox_ClubPage_OtherLocations_DUMBO_2300x1533-min.jpg",
  slug: "DUMBO",
  spa: { phoneNumber: "(347) 435-2341" },
  lat: 40.702853,
  long: -73.9890795,
  region: "New York/Brooklyn",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const EAST_43RD_STREET = {
  id: 108,
  name: "Equinox East 43rd Street",
  path: "/clubs/new-york/midtown/east43rd",
  address: "521 Fifth Ave",
  city: "New York",
  state: "NY",
  zip: "10175",
  country: "US",
  phoneNumber: "(212) 972-8000",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/2Cpx5aeSv9MXalnctHgmA5/e3f2f5f3b414cfc389cca93365fb841b/Equinox_ClubPage_OtherLocations_E43rdSt_2300x1533-min.jpg",
  slug: "EAST_43RD_STREET",
  spa: { phoneNumber: "(212) 972-8000" },
  lat: 40.7541602,
  long: -73.9797976,
  region: "New York/Midtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const EAST_44TH_STREET = {
  id: 109,
  name: "Equinox East 44th Street",
  path: "/clubs/new-york/midtown/east44th",
  address: "420 Lexington Ave",
  city: "New York",
  state: "NY",
  zip: "10170",
  country: "US",
  phoneNumber: "(212) 953-2499",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6HgyZrokH1GDfm3calpS6E/f7b158c206d24a8f77e9f6ae09129daf/Equinox_ClubPage_OtherLocations_E43rdSt_2300x1533-min.jpg",
  slug: "EAST_44TH_STREET",
  spa: { phoneNumber: "(212) 953-2499" },
  lat: 40.7526842,
  long: -73.9759022,
  region: "New York/Midtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const EAST_53RD_STREET = {
  id: 133,
  name: "Equinox East 53rd Street",
  path: "/clubs/new-york/midtown/east53rd",
  address: "10 East 53rd St",
  city: "New York",
  state: "NY",
  zip: "10022",
  country: "US",
  phoneNumber: "(646) 863-1586",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/4vGpgGrIm70PbYEgp0oWQ4/98f14c4fae35916f8bd91b666d8105ed/Equinox_ClubPage_OtherLocations_E53rdS_2300x1533-min.jpg",
  slug: "EAST_53RD_STREET",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 40.7597928,
  long: -73.9753584,
  region: "New York/Midtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const EAST_54TH_STREET = {
  id: 106,
  name: "Equinox East 54th Street",
  path: "/clubs/new-york/midtown/east54th",
  address: "250 East 54th St",
  city: "New York",
  state: "NY",
  zip: "10022",
  country: "US",
  phoneNumber: "(212) 277-5400",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/7o2cp99gc7dqyNgV64GM80/1a3c15673a225300cb7d18f27f3dcc9e/Equinox_ClubPage_OtherLocations_E54thSt_2300x1533-min.jpg",
  slug: "EAST_54TH_STREET",
  spa: { phoneNumber: "(212) 277-5400" },
  lat: 40.757383,
  long: -73.967264,
  region: "New York/Midtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const EAST_61ST_STREET = {
  id: 132,
  name: "Equinox East 61st Street",
  path: "/clubs/new-york/uptown/east61st",
  address: "330 E 61st St",
  city: "New York",
  state: "NY",
  zip: "10065",
  country: "US",
  phoneNumber: "(212) 355-5100",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/7FK8cSLiteA61hEZhxuj9H/0269cce2bb5f0644da00751c37de884d/Equinox_ClubPage_OtherLocations_E61stSt_2300x1533-min.jpg",
  slug: "EAST_61ST_STREET",
  lat: 40.7610658,
  long: -73.9619791,
  region: "New York/Uptown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const EAST_63RD_STREET = {
  id: 105,
  name: "Equinox East 63rd Street",
  path: "/clubs/new-york/uptown/east63rd",
  address: "817 Lexington Ave",
  city: "New York",
  state: "NY",
  zip: "10065",
  country: "US",
  phoneNumber: "(212) 750-4900",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/57os9YObnJRU6loso7DvLC/21f8817de46082fce0fb0f2b07e10d82/Equinox_ClubPage_OtherLocations_E63rdSt_2300x1533-min.jpg",
  slug: "EAST_63RD_STREET",
  spa: { phoneNumber: "(212) 750-4671" },
  lat: 40.7644,
  long: -73.966204,
  region: "New York/Uptown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const EAST_74TH_STREET = {
  id: 117,
  name: "Equinox East 74th Street",
  path: "/clubs/new-york/uptown/east74th",
  address: "1429 2nd Ave",
  city: "New York",
  state: "NY",
  zip: "10021",
  country: "US",
  phoneNumber: "(212) 249-3917",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/GpamQGCIENPqhJrqX0ysT/5d55e8f883b7ef4c16102197ec208d6e/Equinox_ClubPage_OtherLocations_E74thSt_2300x1533-min.jpg",
  slug: "EAST_74TH_STREET",
  spa: { phoneNumber: "(212) 249-3917" },
  lat: 40.77053,
  long: -73.957461,
  region: "New York/Uptown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const EAST_85TH_STREET = {
  id: 104,
  name: "Equinox East 85th Street",
  path: "/clubs/new-york/uptown/east85th",
  address: "203 East 85th St",
  city: "New York",
  state: "NY",
  zip: "10028",
  country: "US",
  phoneNumber: "(212) 439-8500",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/yW8ISoHXabw4FBDMIXh8w/33708acd3ee8659ba0bc088a248e56ce/Equinox_ClubPage_OtherLocations_E85thSt_2300x1533-min.jpg",
  slug: "EAST_85TH_STREET",
  spa: { phoneNumber: "(212) 396-9611" },
  lat: 40.7781098,
  long: -73.9540043,
  region: "New York/Uptown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const EAST_92ND_STREET = {
  id: 129,
  name: "Equinox East 92nd Street",
  path: "/clubs/new-york/uptown/east92nd",
  address: "203 East 92nd St",
  city: "New York",
  state: "NY",
  zip: "10128",
  country: "US",
  phoneNumber: "(212) 671-1884",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/7MaM3pjCwcE6AzYK6doSjE/ef2f18eff2487b26fb2ee5761a8e8d39/Equinox_ClubPage_OtherLocations_E92ndS_2300x1533-min.jpg",
  slug: "EAST_92ND_STREET",
  spa: { phoneNumber: "(212) 671-1884" },
  lat: 40.782774,
  long: -73.950511,
  region: "New York/Uptown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const ENCINO = {
  id: 714,
  name: "Equinox Encino",
  path: "/clubs/southern-california/the-valley/encino",
  address: "16830 Ventura Blvd",
  city: "Encino",
  state: "CA",
  zip: "91436",
  country: "US",
  phoneNumber: "(818) 922-0799",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/1S9GgINvtm8R3Jwg0ulZ5V/e467ccf3d52c24d366e4b099a4ae7a2d/Equinox_ClubPage_Features_Encino_2300x3067_____3-min.jpg",
  slug: "ENCINO",
  lat: 34.1587932,
  long: -118.5008354,
  region: "Southern California/The Valley",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const EQUINOX_X_HAMPTONS = {
  id: 4147,
  name: "Equinox x Hamptons",
  path: "/clubs/new-york/long-island/hamptons",
  address: "204 Butter Ln",
  city: "Bridgehampton",
  state: "NY",
  zip: "11932",
  country: "US",
  phoneNumber: "(631) 237-7012",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/5x8oQVlAJ9q8hOqrDXHA3X/ceba9c324617fba8864a44f930475582/hamptons-mb.jpg",
  slug: "EQUINOX_X_HAMPTONS",
  lat: 40.939867,
  long: -72.31166,
  region: "New York/Long Island",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const E_AT_COLUMBUS_CIRCLE = {
  id: 11301,
  name: "Equinox E at Columbus Circle",
  path: "/clubs/new-york/uptown/ecolumbuscircle",
  address: "10 Columbus Circle",
  city: "New York",
  state: "NY",
  zip: "10019",
  country: "US",
  phoneNumber: "(212) 871-3001",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/7hkVJNT87fGFOZdYWwkd4G/356fc3e9602213da04aa266302d35847/Equinox_ClubPage_OtherLocations_ColumbusCircle_2300x1533-min.jpg",
  slug: "E_AT_COLUMBUS_CIRCLE",
  lat: 40.7687596,
  long: -73.9832053,
  region: "New York/Uptown",
  type: "E",
  timeZone: timeZones.america.newYork,
};

export const E_AT_GREENWICH_CT = {
  id: 14801,
  name: "Equinox E at Greenwich CT",
  path: "/clubs/connecticut/e-greenwich-ct",
  address: "16 Old Track Rd",
  city: "Greenwich",
  state: "CT",
  zip: "06830",
  country: "US",
  phoneNumber: "(203) 863-0070",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/78mu4s0f3K1x9Tp41y8OJR/b2aa0f2fdf5f442dafe96ab7d6a96a80/Equinox_ClubPage_Locations_GreenwichCT_2300x1533-min.jpg",
  slug: "E_AT_GREENWICH_CT",
  lat: 41.0199622,
  long: -73.6344871,
  region: "Connecticut",
  type: "E",
  timeZone: timeZones.america.newYork,
};

export const E_AT_HUDSON_YARDS = {
  id: 5138,
  name: "Equinox E at Hudson Yards",
  path: "/clubs/new-york/midtown/ehudsonyards",
  address: "32 Hudson Yards",
  city: "New York",
  state: "NY",
  zip: "10001",
  country: "US",
  phoneNumber: "(212) 328-9050",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/01DWhonIA47w73REbUHuKK/2c5c236993439f3185180cecc46e7488/Equinox_ClubPage_OtherLocations_HudsonYards_2300x1533-min.jpg",
  slug: "E_AT_HUDSON_YARDS",
  lat: 40.7547,
  long: -74.0038,
  region: "New York/Midtown",
  type: "E",
  timeZone: timeZones.america.newYork,
};

export const E_MADISON_AVENUE = {
  id: 139,
  name: "Equinox E Madison Avenue",
  path: "/clubs/new-york/uptown/emadison",
  address: "30 East 85th St",
  city: "New York",
  state: "NY",
  zip: "10028",
  country: "US",
  phoneNumber: "(212) 501-1490",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/11vfgKknCzVqAuwPa77oaj/ac2770dcfacd668a76b4cf1dbeb2d574/Equinox_ClubPage_OtherLocations_EMadison_2300x1533-min.jpg",
  slug: "E_MADISON_AVENUE",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 40.780166,
  long: -73.959614,
  region: "New York/Uptown",
  type: "E",
  timeZone: timeZones.america.newYork,
};

export const E_SAN_FRANCISCO = {
  id: 5724,
  name: "Equinox E San Francisco",
  path: "/clubs/northern-california/eclubescsf",
  address: "747 Market St",
  city: "San Francisco",
  state: "CA",
  zip: "94103",
  country: "US",
  phoneNumber: "(415) 633-3900",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6QktoZzv561d66RevErroe/5ecdcc6d4f6b742ddd1f3f14b4d0724c/Equinox_ClubPage_OtherLocations_ESCSanFran_2300x1533.jpg",
  slug: "E_SAN_FRANCISCO",
  lat: 37.786664,
  long: -122.4044,
  region: "Northern California",
  type: "E",
  timeZone: timeZones.america.losAngeles,
};

export const E_ST_JAMESS = {
  id: 873,
  name: "Equinox E St. James's",
  path: "/clubs/london/stjames",
  address: "12 St James's St",
  city: "London",
  state: "",
  zip: "SW1A 1ER",
  country: "UK",
  phoneNumber: "+44 (0) 203 884 2071",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/1FHuZfUsNtb9XbBkYfgZf5/ee3829fcec7e50ebdd4eac398c5c6b1a/Equinox_ClubPage_OtherLocations_EastStJames___s_2300x1533-min.jpg",
  slug: "E_ST_JAMESS",
  spa: { phoneNumber: "+44 (0) 203 884 2071" },
  lat: 51.506105,
  long: -0.138713,
  region: "London",
  type: "E",
  timeZone: timeZones.europe.london,
};

export const FLATIRON = {
  id: 102,
  name: "Equinox Flatiron",
  path: "/clubs/new-york/downtown/flatiron",
  address: "897 Broadway",
  city: "New York",
  state: "NY",
  zip: "10003",
  country: "US",
  phoneNumber: "(212) 780-9300",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/75q2Ydn0OCz7XMKq2TAz7D/e30276dfa1c5102defa473a277a391f5/Equinox_ClubPage_OtherLocations_Flatiron_2300x1533-min.jpg",
  slug: "FLATIRON",
  spa: { phoneNumber: "(212) 780-9300" },
  lat: 40.7390882,
  long: -73.9900893,
  region: "New York/Downtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const FRANKLIN_STREET = {
  id: 202,
  name: "Equinox Franklin Street",
  path: "/clubs/boston/franklinst",
  address: "225 Franklin St",
  city: "Boston",
  state: "MA",
  zip: "02110",
  country: "US",
  phoneNumber: "(617) 426-2140",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/7ouaaIg4MA5qx7uvVqdATh/6ea4e6b16b054d8a20f04a934b8b3427/Equinox_ClubPage_Locations_FranklinSt_2300x1533-min.jpg",
  slug: "FRANKLIN_STREET",
  spa: { phoneNumber: "(617) 375-8520" },
  lat: 42.3559366,
  long: -71.0541512,
  region: "Boston",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const GLENDALE = {
  id: 718,
  name: "Equinox Glendale",
  path: "/clubs/southern-california/the-valley/glendale",
  address: "207 Goode Ave",
  city: "Glendale",
  state: "CA",
  zip: "91203",
  country: "US",
  phoneNumber: "(818) 334-6841",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/DlAzA3hkxm3kg9ynl9Ol7/81eaeae01db29089aeac276532aa6c7c/Equinox_ClubPage_OtherLocations_Glendale_2300x1533-min.jpg",
  slug: "GLENDALE",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 34.1570818,
  long: -118.2565333,
  region: "Southern California/The Valley",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const GOLD_COAST = {
  id: 402,
  name: "Equinox Gold Coast",
  path: "/clubs/chicago/goldcoast",
  address: "900 North Michigan",
  city: "Chicago",
  state: "IL",
  zip: "60611",
  country: "US",
  phoneNumber: "(312) 254-2500",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6UYDWTHB0s1El9WvChlmQu/51ffafa9e9345f01324c480dca2119ef/Equinox_ClubPage_Locations_GoldCoast_2300x1533.jpg",
  slug: "GOLD_COAST",
  spa: { phoneNumber: "(312) 254-2500" },
  lat: 41.899641,
  long: -87.625122,
  region: "Chicago",
  type: "Regular",
  timeZone: timeZones.america.chicago,
};

export const GRAMERCY = {
  id: 136,
  name: "Equinox Gramercy",
  path: "/clubs/new-york/downtown/gramercy",
  address: "315 Park Ave South",
  city: "New York",
  state: "NY",
  zip: "10010",
  country: "US",
  phoneNumber: "(212) 320-9870",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/5KmuJnNYxDoXYKoGKpnk4A/d65aaee6a471082a9816d44840f4916f/Equinox_ClubPage_OtherLocations_Gramercy_2300x1533-min.jpg",
  slug: "GRAMERCY",
  spa: { phoneNumber: "(212) 320-9870" },
  lat: 40.7404924,
  long: -73.9856205,
  region: "New York/Downtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const GREAT_NECK = {
  id: 145,
  name: "Equinox Great Neck",
  path: "/clubs/new-york/long-island/greatneck",
  address: "90 E. Shore Rd",
  city: "Great Neck",
  state: "NY",
  zip: "11023",
  country: "US",
  phoneNumber: "(516) 570-8003",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/2nUZCRupwDkMo61DG37ol8/ced1bb4992fe31dd63c69583eba09473/Equinox_ClubPage_OtherLocations_GreatNeck_2300x1533-min.jpg",
  slug: "GREAT_NECK",
  spa: { phoneNumber: "(516) 570-8003" },
  lat: 40.791552,
  long: -73.7091057,
  region: "New York/Long Island",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const GREENWICH_AVENUE = {
  id: 112,
  name: "Equinox Greenwich Avenue",
  path: "/clubs/new-york/downtown/greenwichave",
  address: "97 Greenwich Ave",
  city: "New York",
  state: "NY",
  zip: "10014",
  country: "US",
  phoneNumber: "(212) 620-0103",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/3eg09wNww0dnncNCCSw6l1/dae585e3bd85c10fbed1c74ae9898f3a/Pool_Greenwich_DT.jpg",
  slug: "GREENWICH_AVENUE",
  spa: { phoneNumber: "(212) 620-0103" },
  lat: 40.737496,
  long: -74.002017,
  region: "New York/Downtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const GREENWICH_CT = {
  id: 148,
  name: "Equinox Greenwich CT",
  path: "/clubs/connecticut/greenwich-ct",
  address: "16 Old Track Rd",
  city: "Greenwich",
  state: "CT",
  zip: "06830",
  country: "US",
  phoneNumber: "(203) 863-0070",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/78mu4s0f3K1x9Tp41y8OJR/b2aa0f2fdf5f442dafe96ab7d6a96a80/Equinox_ClubPage_Locations_GreenwichCT_2300x1533-min.jpg",
  slug: "GREENWICH_CT",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 41.0199622,
  long: -73.6344871,
  region: "Connecticut",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const HIGHLAND_PARK_DALLAS = {
  id: 451,
  name: "Equinox Highland Park Dallas",
  path: "/clubs/texas/highlandpark-dallas",
  address: "4023 Oak Lawn Ave",
  city: "Dallas",
  state: "TX",
  zip: "75219",
  country: "US",
  phoneNumber: "(214) 443-9009",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/pt6d6yOOftuEeumMBhjiP/743c844f475cd0710a84ff10a42437fc/Equinox_ClubPage_OtherLocations_HighlandPark_2300x1533-min.jpg",
  slug: "HIGHLAND_PARK_DALLAS",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 32.8180318,
  long: -96.8029574,
  region: "Texas",
  type: "Regular",
  timeZone: timeZones.america.chicago,
};

export const HIGH_LINE = {
  id: 116,
  name: "Equinox High Line",
  path: "/clubs/new-york/downtown/highline",
  address: "100 Tenth Ave",
  city: "New York",
  state: "NY",
  zip: "10011",
  country: "US",
  phoneNumber: "(212) 367-0863",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/5wdScAUq2At56WuMwXZHnd/4d25d331217397bf48f1ea60d2d4fec8/Equinox_ClubPage_Spaces_DT_Highline_3200x2133_____2-min.jpeg",
  slug: "HIGH_LINE",
  spa: { phoneNumber: "(212) 367-0863" },
  lat: 40.7438299,
  long: -74.0067247,
  region: "New York/Downtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const HOLLYWOOD = {
  id: 717,
  name: "Equinox Hollywood",
  path: "/clubs/southern-california/los-angeles/hollywood",
  address: "1550 N. Vine St",
  city: "Hollywood",
  state: "CA",
  zip: "90028",
  country: "US",
  phoneNumber: "(323) 471-0130",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/4i7qiDkFLhfU7H0qArET0z/8b9812c92d2e45abddc6a28985276270/Equinox_ClubPage_OtherLocations_Glendale_2300x1533-min.jpg",
  slug: "HOLLYWOOD",
  spa: { phoneNumber: "(323) 471-0130" },
  lat: 34.099845,
  long: -118.326128,
  region: "Southern California/Los Angeles",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const HUDSON_YARDS = {
  id: 138,
  name: "Equinox Hudson Yards",
  path: "/clubs/new-york/midtown/hudsonyards",
  address: "32 Hudson Yards",
  city: "New York",
  state: "NY",
  zip: "10001",
  country: "US",
  phoneNumber: "(212) 328-9050",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/01DWhonIA47w73REbUHuKK/2c5c236993439f3185180cecc46e7488/Equinox_ClubPage_OtherLocations_HudsonYards_2300x1533-min.jpg",
  slug: "HUDSON_YARDS",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 40.7547,
  long: -74.0038,
  region: "New York/Midtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const HUNTINGTON_BEACH = {
  id: 733,
  name: "Equinox Huntington Beach",
  path: "/clubs/southern-california/orange-county/huntingtonbeach",
  address: "21010 Pacific Coast Highway, Suite A105",
  city: "Huntington Beach",
  state: "CA",
  zip: "92648",
  country: "US",
  phoneNumber: "(657) 215-4710",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/76NNhnA4kc7v2GacHY43jH/4af6655c9db23e011a8e1681a774b0f5/Equinox_ClubPage_OtherLocations_HuntingtonBeach_2300x1533-min.jpg",
  slug: "HUNTINGTON_BEACH",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 33.6554736,
  long: -117.9994716,
  region: "Southern California/Orange County",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const KENSINGTON = {
  id: 871,
  name: "Equinox Kensington",
  path: "/clubs/london/kensington",
  address: "99 Kensington High St",
  city: "London",
  state: "",
  zip: "W8 5SA",
  country: "UK",
  phoneNumber: "+44 (0) 207 666 6000",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/5IMG3U06xmTsvdEGxf4g9W/5c48c5fdc088f80b76eeac5333a3db23/Equinox_ClubPage_OtherLocations_Kensington_2300x1533-min.jpg",
  slug: "KENSINGTON",
  spa: { phoneNumber: "+44 (0) 207 666 6000" },
  lat: 51.5010397,
  long: -0.1914084,
  region: "London",
  type: "Regular",
  timeZone: timeZones.europe.london,
};

export const LA_COSTA = {
  id: 734,
  name: "Equinox La Costa",
  path: "/clubs/southern-california/san-diego/lacosta",
  address: "7710 El Camino Real",
  city: "Carlsbad",
  state: "CA",
  zip: "92009",
  country: "US",
  phoneNumber: "(760) 268-9657",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/1gU7YZUb1isfX7tY0wdXTq/1f2e41e0f986e1aedcbd607a94e2d512/Equinox_ClubPage_OtherLocations_LaCosta_2300x1533-min.jpg",
  slug: "LA_COSTA",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 33.0847221,
  long: -117.2672044,
  region: "Southern California/San Diego",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const LINCOLN_COMMON = {
  id: 405,
  name: "Equinox Lincoln Common",
  path: "/clubs/chicago/lincolncommon",
  address: "2355 North Lincoln Ave",
  city: "Chicago",
  state: "IL",
  zip: "60614",
  country: "US",
  phoneNumber: "(312) 796-6850",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/5jWkzUJUuWerIr8NJR8Oo9/57448dfab3f5cf2b1a9ba2e7a65688e2/Equinox_ClubPage_OtherLocations_LincolnCommon_2300x1533.jpg",
  slug: "LINCOLN_COMMON",
  lat: 41.92468,
  long: -87.647849,
  region: "Chicago",
  type: "Regular",
  timeZone: timeZones.america.chicago,
};

export const LINCOLN_PARK = {
  id: 401,
  name: "Equinox Lincoln Park",
  path: "/clubs/chicago/lincolnpark",
  address: "1750 North Clark St",
  city: "Chicago",
  state: "IL",
  zip: "60614",
  country: "US",
  phoneNumber: "(312) 254-4000",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/379B2AOe3GudMLCmd12a5q/b7b32391751f306cfa4c6a4110d17848/Equinox_ClubPage_Locations_LincolnPark_2300x1533.jpg",
  slug: "LINCOLN_PARK",
  spa: { phoneNumber: "(312) 254-4000" },
  lat: 41.9142642,
  long: -87.6339586,
  region: "Chicago",
  type: "Regular",
  timeZone: timeZones.america.chicago,
};

export const MAMARONECK = {
  id: 144,
  name: "Equinox Mamaroneck",
  path: "/clubs/new-york/westchester/mamaroneck",
  address: "1053 West Boston Post Rd",
  city: "Mamaroneck",
  state: "NY",
  zip: "10543",
  country: "US",
  phoneNumber: "(914) 777-1919",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/54iomQAHlxJz5FL4oRr4w/28c2a84322a649b14b3af3beef09ab61/Equinox_ClubPage_OtherLocations_Mamaroneck_2300x1533-min.jpg",
  slug: "MAMARONECK",
  spa: { phoneNumber: "(914) 777-1919" },
  lat: 40.9396058,
  long: -73.7432213,
  region: "New York/Westchester",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const MARINA_DEL_REY = {
  id: 711,
  name: "Equinox Marina del Rey",
  path: "/clubs/southern-california/south-bay/marina",
  address: "13455 Maxella Ave, Suite 204",
  city: "Marina del Rey",
  state: "CA",
  zip: "90292",
  country: "US",
  phoneNumber: "(310) 306-1000",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/2ObVkOvClcPcUtnojiwsO9/7f990a58fff40de8905513cb1ba00e8b/Equinox_ClubPage_OtherLocations_MarinaDelRay_2300x1533-min.jpg",
  slug: "MARINA_DEL_REY",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 33.9867604,
  long: -118.4416594,
  region: "Southern California/South Bay",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const MIRACLE_MILE = {
  id: 751,
  name: "Equinox Miracle Mile",
  path: "/clubs/southern-california/los-angeles/miraclemile",
  address: "5750 Wilshire Blvd",
  city: "Los Angeles",
  state: "CA",
  zip: "90036",
  country: "US",
  phoneNumber: "(323) 405-6405",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/2wUn6z8D6AQNakqIAzvRVg/dacd8f357cbc6ae59a120f6ffe0ddcd5/Equinox_ClubPage_OtherLocations_LaCosta_2300x1533.jpg",
  slug: "MIRACLE_MILE",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 34.061597,
  long: -118.354772,
  region: "Southern California/Los Angeles",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const NEWPORT_BEACH = {
  id: 730,
  name: "Equinox Newport Beach",
  path: "/clubs/southern-california/orange-county/newportbeach",
  address: "19540 Jamboree Rd",
  city: "Irvine",
  state: "CA",
  zip: "92612",
  country: "US",
  phoneNumber: "(949) 296-1700",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/61ipc34RXwGwAPm02iqVvu/22849cccc6d85b991af6b8ce52262999/Equinox_ClubPage_OtherLocations_NewportBeach_2300x1533.jpg",
  slug: "NEWPORT_BEACH",
  spa: { phoneNumber: "(949) 296-1700" },
  lat: 33.65895,
  long: -117.8604083,
  region: "Southern California/Orange County",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const NOMAD = {
  id: 160,
  name: "Equinox Nomad",
  path: "/clubs/new-york/downtown/nomad",
  address: "31 West 27th St",
  city: "New York",
  state: "NY",
  zip: "10010",
  country: "US",
  phoneNumber: "(646) 783-3999",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6weNZO1i58cd0mkA0Zl8bQ/4900b08601b8b91eb7482ec227406d32/EQUINOX_NOMAD_RECEPTION_021_HDR_1-scr.jpg",
  slug: "NOMAD",
  lat: 40.7449018,
  long: -73.9894613,
  region: "New York/Downtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const ORCHARD_STREET = {
  id: 122,
  name: "Equinox Orchard Street",
  path: "/clubs/new-york/downtown/orchardst",
  address: "200 Orchard St",
  city: "New York",
  state: "NY",
  zip: "10002",
  country: "US",
  phoneNumber: "(212) 501-1450",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/2Ykdbcv2aXVyv3T8J8rMdP/c80b458bc7b8c6171bbc7a23a1fde6eb/Equinox_ClubPage_OtherLocations_OrchardStreet_2300x1533-min.jpg",
  slug: "ORCHARD_STREET",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 40.722267,
  long: -73.987821,
  region: "New York/Downtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const PACIFIC_PALISADES = {
  id: 710,
  name: "Equinox Pacific Palisades",
  path: "/clubs/southern-california/pacific-palisades",
  address: "17383 Sunset Blvd",
  city: "Pacific Palisades",
  state: "CA",
  zip: "92072",
  country: "US",
  phoneNumber: "(866) 332-6549",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/owSXLXrZvNfkrrq6SoCHg/e0a49f53e382ee85d41d78d1cae65b36/shoreditch_otherlocations.png",
  slug: "PACIFIC_PALISADES",
  lat: 34.0392412,
  long: -118.5557199,
  region: "Southern California/Los Angeles",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const PALOS_VERDES = {
  id: 705,
  name: "Equinox Palos Verdes",
  path: "/clubs/southern-california/south-bay/palosverdes",
  address: "550 Deep Valley Dr, Suite 212",
  city: "Rolling Hills Estates",
  state: "CA",
  zip: "90274",
  country: "US",
  phoneNumber: "(310) 697-1016",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6r0VV55fyugMB35ENbZRjy/11daa0aa20bb8bf274e310d865c3ae79/Equinox_ClubPage_OtherLocations_PalosVerdes_2300x1533.jpg",
  slug: "PALOS_VERDES",
  spa: { phoneNumber: "(310) 697-1016" },
  lat: 33.7721263,
  long: -118.3733576,
  region: "Southern California/South Bay",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const PALO_ALTO = {
  id: 722,
  name: "Equinox Palo Alto",
  path: "/clubs/northern-california/paloalto",
  address: "440 Portage Ave",
  city: "Palo Alto",
  state: "CA",
  zip: "94306",
  country: "US",
  phoneNumber: "(650) 319-1700",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/qhLyBjY9TZHfmMzSSMkIz/1411a8f2703a50d425eb6b96d327952e/Equinox_ClubPage_OtherLocations_PaloAlto_2300x1533.jpg",
  slug: "PALO_ALTO",
  spa: { phoneNumber: "(650) 319-1700" },
  lat: 37.4219781,
  long: -122.1380779,
  region: "Northern California",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const PARAMUS = {
  id: 151,
  name: "Equinox Paramus",
  path: "/clubs/new-jersey/paramus",
  address: "776 Route 17 North",
  city: "Paramus",
  state: "NJ",
  zip: "07652",
  country: "US",
  phoneNumber: "(201) 518-7999",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/7bjXk1M9FPcQIMyCLIE9M3/21fe358408c7465c1593925dfffa9aff/Equinox_ClubPage_OtherLocations_Paramus_2300x1533-min.jpg",
  slug: "PARAMUS",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 40.9729964,
  long: -74.0796539,
  region: "New Jersey",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const PARK_AVENUE = {
  id: 115,
  name: "Equinox Park Avenue",
  path: "/clubs/new-york/midtown/park",
  address: "1 Park Ave",
  city: "New York",
  state: "NY",
  zip: "10016",
  country: "US",
  phoneNumber: "(212) 779-1727",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6ovpidLYHSePogWaRF8Zab/861c2305e024184959d5bec8a26feff9/Equinox_ClubPage_OtherLocations_ParkAve_2300x1533-min.jpg",
  slug: "PARK_AVENUE",
  spa: { phoneNumber: "(212) 779-1727" },
  lat: 40.745885,
  long: -73.9815182,
  region: "New York/Midtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const PASADENA = {
  id: 701,
  name: "Equinox Pasadena",
  path: "/clubs/southern-california/the-valley/pasadena",
  address: "300 E. Colorado Blvd, Suite 201",
  city: "Pasadena",
  state: "CA",
  zip: "91101",
  country: "US",
  phoneNumber: "(626) 685-4800",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/1cGikMCIResZhGeEGZBVUB/ba8f80911379d35e65d307e805d39571/Equinox_ClubPage_OtherLocations_Pasadena_2300x1533-min.jpg",
  slug: "PASADENA",
  spa: { phoneNumber: "(626) 685-4800" },
  lat: 34.1456259,
  long: -118.1440236,
  region: "Southern California/The Valley",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const PINE_STREET = {
  id: 720,
  name: "Equinox Pine Street",
  path: "/clubs/northern-california/pinest",
  address: "301 Pine St",
  city: "San Francisco",
  state: "CA",
  zip: "94104",
  country: "US",
  phoneNumber: "(415) 593-4000",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/XYyQjP73KwoSNjI7BrO4h/e23c43a9d65c482e6c79827eba4ceb61/Equinox_ClubPage_OtherLocations_PineSt_2300x1533-min.jpg",
  slug: "PINE_STREET",
  spa: { phoneNumber: "(415) 593-4000" },
  lat: 37.7918522,
  long: -122.4012807,
  region: "Northern California",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const PLANO = {
  id: 455,
  name: "Equinox Plano",
  path: "/clubs/texas/plano",
  address: "6109 West Park Blvd",
  city: "Plano",
  state: "TX",
  zip: "75093",
  country: "US",
  phoneNumber: "(214) 919-7409",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/8rC9AikfYTC9WeCUh6ZDV/4c59f0a49d253630dcee9e785327734c/Equinox_ClubPage_OtherLocations_Plano_2300x1533-min.jpg",
  slug: "PLANO",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 33.0281526,
  long: -96.8341194,
  region: "Texas",
  type: "Regular",
  timeZone: timeZones.america.chicago,
};

export const PRESTON_HOLLOW = {
  id: 452,
  name: "Equinox Preston Hollow",
  path: "/clubs/texas/prestonhollow",
  address: "8611 Hillcrest Rd",
  city: "Dallas",
  state: "TX",
  zip: "75225",
  country: "US",
  phoneNumber: "(214) 265-5512",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/zTBpU5Y0eS4oic28BbZ7N/14c1726c678649d2d074f3eaa45d57bd/Equinox_ClubPage_OtherLocations_PrestonHollow_2300x1533-min.jpg",
  slug: "PRESTON_HOLLOW",
};

export const PRINTING_HOUSE = {
  id: 124,
  name: "Equinox Printing House",
  path: "/clubs/new-york/downtown/printinghouse",
  address: "421 Hudson St",
  city: "New York",
  state: "NY",
  zip: "10014",
  country: "US",
  phoneNumber: "(212) 243-7600",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/7FVLjxnpCyw3pS3WzyGTMK/c9e8b17654c7f2668dca233bf9b9a22c/Equinox_ClubPage_OtherLocations_PrintingHouse_2300x1533-min.jpg",
  slug: "PRINTING_HOUSE",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 40.7298841,
  long: -74.0072519,
  region: "New York/Downtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const RAINIER_SQUARE = {
  id: 790,
  name: "Equinox Rainier Square",
  path: "/clubs/washington/rainiersquare",
  address: "401 Union St",
  city: "Seattle",
  state: "WA",
  zip: "98101",
  country: "US",
  phoneNumber: "(866) 332-6549",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/owSXLXrZvNfkrrq6SoCHg/e0a49f53e382ee85d41d78d1cae65b36/shoreditch_otherlocations.png",
  slug: "RAINIER_SQUARE",
  lat: 47.60898,
  long: -122.33476,
  region: "Washington/Seattle",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const RITTENHOUSE = {
  id: 240,
  name: "Equinox Rittenhouse",
  path: "/clubs/pennsylvania/rittenhouse",
  address: "1907 Walnut St",
  city: "Philadelphia",
  state: "PA",
  zip: "19103",
  country: "US",
  phoneNumber: "(267) 858-3500",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/owSXLXrZvNfkrrq6SoCHg/e0a49f53e382ee85d41d78d1cae65b36/shoreditch_otherlocations.png",
  slug: "RITTENHOUSE",
  spa: { phoneNumber: "(267) 858-3500" },
  lat: 39.9505756,
  long: -75.1730227,
  region: "Pennsylvania/Philadelphia",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const RIVER_OAKS = {
  id: 454,
  name: "Equinox River Oaks",
  path: "/clubs/texas/riveroaks",
  address: "4444 Westheimer Rd",
  city: "Houston",
  state: "TX",
  zip: "77027",
  country: "US",
  phoneNumber: "(281) 936-0963",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/216kujZ9xKy1D9Isssdf1D/b44ebfc691b11f02468c9691f281a015/Equinox_ClubPage_OtherLocations_RiverOaks_2300x1533-min.jpg",
  slug: "RIVER_OAKS",
  spa: { phoneNumber: "(281) 936-0963" },
  lat: 29.741911,
  long: -95.453213,
  region: "Texas",
  type: "Regular",
  timeZone: timeZones.america.chicago,
};

export const ROCKEFELLER_CENTER = {
  id: 126,
  name: "Equinox Rockefeller Center",
  path: "/clubs/new-york/midtown/rockefeller",
  address: "45 Rockefeller Plaza",
  city: "New York",
  state: "NY",
  zip: "10111",
  country: "US",
  phoneNumber: "(212) 218-8600",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/49SjwiAzU6xqq8xtBOgsqd/1d158b4cf4e7e4cfd04115a4204f420f/Equinox_ClubPage_OtherLocations_RockefellerHouse_2300x1533-min.jpg",
  slug: "ROCKEFELLER_CENTER",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 40.7591523,
  long: -73.9777136,
  region: "New York/Midtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const ROSLYN = {
  id: 143,
  name: "Equinox Roslyn",
  path: "/clubs/new-york/long-island/roslyn",
  address: "90 Northern Blvd",
  city: "Greenvale",
  state: "NY",
  zip: "11548",
  country: "US",
  phoneNumber: "(516) 403-4444",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/4gBELVGyu0ZdABwzcXJnyS/4799282f838429707c7a8329bab35edf/Equinox_ClubPage_Spaces_DT_CenturyCity_3200x2133_____1-min.jpg",
  slug: "ROSLYN",
  spa: { phoneNumber: "(516) 403-4444" },
  lat: 40.808251,
  long: -73.6289233,
  region: "New York/Long Island",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const SANTA_MONICA = {
  id: 703,
  name: "Equinox Santa Monica",
  path: "/clubs/southern-california/los-angeles/santamonica",
  address: "201 Santa Monica Blvd",
  city: "Santa Monica",
  state: "CA",
  zip: "90401",
  country: "US",
  phoneNumber: "(310) 593-8888",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/39cHEeE0ARksjnZcF3xx22/674a9af40cc19fed58696e8b9e986225/Equinox_ClubPage_OtherLocations_SantaMonica_2300x1533-min.jpg",
  slug: "SANTA_MONICA",
  spa: { phoneNumber: "(310) 593-8888" },
  lat: 34.0152648,
  long: -118.4971328,
  region: "Southern California/Los Angeles",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const SAN_MATEO = {
  id: 721,
  name: "Equinox San Mateo",
  path: "/clubs/northern-california/sanmateo",
  address: "4 East 4th Ave",
  city: "San Mateo",
  state: "CA",
  zip: "94401",
  country: "US",
  phoneNumber: "(650) 403-7000",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/nPI2KlDmX7IFSwLkirbHG/1ab1023f002bf57de7f9aa31d41c4dae/Equinox_ClubPage_OtherLocations_SanMateo_2300x1533.jpg",
  slug: "SAN_MATEO",
  spa: { phoneNumber: "(650) 348-6240" },
  lat: 37.5623022,
  long: -122.3246329,
  region: "Northern California",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const SAN_RAMON = {
  id: 725,
  name: "Equinox San Ramon",
  path: "/clubs/northern-california/sanramon",
  address: "6000 Bollinger Canyon Rd",
  city: "San Ramon",
  state: "CA",
  zip: "94583",
  country: "US",
  phoneNumber: "(925) 299-9801",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6pfaLb5QNrOLeWeQlX6MWV/6b03199a9ffca6220edeb9ef22bd77ee/Equinox_ClubPage_OtherLocations_SanRamon_2300x1533-min.jpg",
  slug: "SAN_RAMON",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 37.763225,
  long: -121.95598,
  region: "Northern California",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const SCARSDALE = {
  id: 140,
  name: "Equinox Scarsdale",
  path: "/clubs/new-york/westchester/scarsdale",
  address: "800 White Plains Rd",
  city: "Scarsdale",
  state: "NY",
  zip: "10583",
  country: "US",
  phoneNumber: "(914) 472-9000",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/ny3JLIHic2CmhQ6VApRQU/a8a3a0d7e106b1a19e9bbfba2e15d4a1/Equinox_ClubPage_OtherLocations_Scarsdale_2300x1533-min.jpg",
  slug: "SCARSDALE",
  spa: { phoneNumber: "(914) 472-9000" },
  lat: 40.9751205,
  long: -73.8021677,
  region: "New York/Westchester",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const SEAPORT = {
  id: 206,
  name: "Equinox Seaport",
  path: "/clubs/boston/seaport",
  address: "27 Northern Ave",
  city: "Boston",
  state: "MA",
  zip: "02110",
  country: "US",
  phoneNumber: "(617) 206-2641",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/2hufbeSOwsjEJOZHKsflp0/d26666a090ab0e55509fd4bd3a434a0f/Equinox_ClubPage_OtherLocations_Seaport_2300x1533-min.jpg",
  slug: "SEAPORT",
  spa: { phoneNumber: "(617) 375-8520" },
  lat: 42.352924,
  long: -71.045815,
  region: "Boston",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const SOHO = {
  id: 114,
  name: "Equinox SoHo",
  path: "/clubs/new-york/downtown/soho",
  address: "69 Prince St",
  city: "New York",
  state: "NY",
  zip: "10012",
  country: "US",
  phoneNumber: "(212) 334-4631",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/4d6EcM1gLhHVCNwpN4eWKa/b670e8b5b21caf9840f212b219c871f3/Equinox_ClubPage_OtherLocations_SOHO_2300x1533-min.jpg",
  slug: "SOHO",
  spa: { phoneNumber: "(212) 334-4631" },
  lat: 40.7240841,
  long: -73.9970289,
  region: "New York/Downtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const SOUTHPORT = {
  id: 155,
  name: "Equinox Southport",
  path: "/clubs/connecticut/southport",
  address: "226 Old Post Rd",
  city: "Southport",
  state: "CT",
  zip: "06890",
  country: "US",
  phoneNumber: "(203) 290-3116",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/10B2tSZbbMhOrjDKwx4gRu/91156e74984f23d4e1f01c53d269ccdd/Equinox_ClubPage_OtherLocations_Southport_2300x1533-min.jpg",
  slug: "SOUTHPORT",
  spa: { phoneNumber: "(203) 290-3116" },
  lat: 41.1388563,
  long: -73.2888541,
  region: "Connecticut",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const SOUTH_BAY = {
  id: 708,
  name: "Equinox South Bay",
  path: "/clubs/southern-california/south-bay/southbay",
  address: "5400 Rosecrans Ave",
  city: "Hawthorne",
  state: "CA",
  zip: "90250",
  country: "US",
  phoneNumber: "(310) 727-9543",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/2krJq8PmdvLC4dEWd0ViR8/bf1e5b40c0bfee27e659dde699ffde02/Equinox_ClubPage_OtherLocations_SouthBay_2300x1533-min.jpg",
  slug: "SOUTH_BAY",
  spa: { phoneNumber: "(310) 727-9543" },
  lat: 33.9012956,
  long: -118.3749034,
  region: "Southern California/South Bay",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const SOUTH_BEACH = {
  id: 301,
  name: "Equinox South Beach",
  path: "/clubs/florida/southbeach",
  address: "520 Collins Ave",
  city: "Miami Beach",
  state: "FL",
  zip: "33139",
  country: "US",
  phoneNumber: "(305) 673-1172",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/5BCKl8OZMRhQISpKlEJSe3/e4648230abf9a03047715e989cd3f513/Equinox_ClubPage_OtherLocations_SouthBeach_2300x1533.jpg",
  slug: "SOUTH_BEACH",
  spa: { phoneNumber: "(305) 673-1172" },
  lat: 25.7754172,
  long: -80.1330859,
  region: "Florida",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const SPORTS_CLUB_BOSTON = {
  id: 204,
  name: "Equinox Sports Club Boston",
  path: "/clubs/boston/sportsclubboston",
  address: "4 Avery St",
  city: "Boston",
  state: "MA",
  zip: "02111",
  country: "US",
  phoneNumber: "(617) 375-8200",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/1QT2WLfIZOB5lFAwsgKvUD/abb327a8790293b66966295a20408796/Equinox_ClubPage_OtherLocations_ESCBoston_2300x1533.jpg",
  slug: "SPORTS_CLUB_BOSTON",
  spa: { phoneNumber: "(617) 375-8580" },
  lat: 42.3530525,
  long: -71.0631123,
  region: "Boston",
  type: "SportsClub",
  timeZone: timeZones.america.newYork,
};

export const SPORTS_CLUB_LOS_ANGELES = {
  id: 713,
  name: "Equinox Sports Club Los Angeles",
  path: "/clubs/southern-california/los-angeles/losangeles",
  address: "1835 S. Sepulveda Blvd",
  city: "Los Angeles",
  state: "CA",
  zip: "90025",
  country: "US",
  phoneNumber: "(310) 473-1447",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/2CinhVA6s2XiDEwxeICCbY/9ad383b26b6a6b7d6e9b1d02bbe2bbf1/Equinox_ClubPage_OtherLocations_SCLA_2300x1533.jpg",
  slug: "SPORTS_CLUB_LOS_ANGELES",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 34.0450833,
  long: -118.4425408,
  region: "Southern California/Los Angeles",
  type: "SportsClub",
  timeZone: timeZones.america.losAngeles,
};

export const SPORTS_CLUB_NEW_YORK = {
  id: 131,
  name: "Equinox Sports Club New York",
  path: "/clubs/new-york/uptown/sportsclubnewyork",
  address: "160 Columbus Ave",
  city: "New York",
  state: "NY",
  zip: "10023",
  country: "US",
  phoneNumber: "(212) 362-6800",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/12D78ETgboBoc097ajmRFk/fc48c332ce8830d225e649d9e4cae8ec/Equinox_ClubPage_OtherLocations_ESCNY_2300x1533-min.jpg",
  slug: "SPORTS_CLUB_NEW_YORK",
  spa: { phoneNumber: "(212) 362-6800" },
  lat: 40.7747183,
  long: -73.9813903,
  region: "New York/Uptown",
  type: "SportsClub",
  timeZone: timeZones.america.newYork,
};

export const SPORTS_CLUB_ORANGE_COUNTY = {
  id: 732,
  name: "Equinox Sports Club Orange County",
  path: "/clubs/southern-california/orange-county/orangecounty",
  address: "1980 Main St",
  city: "Irvine",
  state: "CA",
  zip: "92614",
  country: "US",
  phoneNumber: "(949) 975-8400",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/4gmEvgkv5WH0umwA16ixDY/458a84eb06e94eda57195c3713ac77b7/Equinox_ClubPage_OtherLocations_Irvine_2300x1533.jpg",
  slug: "SPORTS_CLUB_ORANGE_COUNTY",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 33.6835126,
  long: -117.8543569,
  region: "Southern California/Orange County",
  type: "SportsClub",
  timeZone: timeZones.america.losAngeles,
};

export const SPORTS_CLUB_SAN_FRANCISCO = {
  id: 724,
  name: "Equinox Sports Club San Francisco",
  path: "/clubs/northern-california/sportsclubsanfrancisco",
  address: "747 Market St",
  city: "San Francisco",
  state: "CA",
  zip: "94103",
  country: "US",
  phoneNumber: "(415) 633-3900",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/3YTFeMsFvGeOJ8KWjuC9KM/9e150bf53d407a9cbd1babb250fee607/Equinox_ClubPage_OtherLocations_ESCSanFran_2300x1533.jpg",
  slug: "SPORTS_CLUB_SAN_FRANCISCO",
  spa: { phoneNumber: "(415) 633-3990" },
  lat: 37.7865509,
  long: -122.4043343,
  region: "Northern California",
  type: "SportsClub",
  timeZone: timeZones.america.losAngeles,
};

export const SPORTS_CLUB_WASHINGTON_DC = {
  id: 253,
  name: "Equinox Sports Club Washington D.C.",
  path: "/clubs/washington-dc/sportsclubwashingtondc",
  address: "1170 22nd St NW",
  city: "Washington",
  state: "DC",
  zip: "20037",
  country: "US",
  phoneNumber: "(202) 974-6600",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/25ud9juOTfZatKG0TmbW33/a80620866954979df304ddca3d406449/Equinox_ClubPage_OtherLocations_ESCWashingtonDC_2300x1533-min.jpg",
  slug: "SPORTS_CLUB_WASHINGTON_DC",
  spa: { phoneNumber: "(202) 974-6601" },
  lat: 38.9047744,
  long: -77.0490978,
  region: "Washington DC",
  type: "SportsClub",
  timeZone: timeZones.america.newYork,
};

export const STUDIO_CITY = {
  id: 709,
  name: "Equinox Studio City",
  path: "/clubs/southern-california/studiocity",
  address: "12833 Ventura Blvd. Unit 101",
  city: "Studio City",
  state: "CA",
  zip: "91604",
  country: "US",
  phoneNumber: "(747) 205-5597",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/7AD8M1hYugmmuJCKKq6JGA/c26b58580603e1cd72dcbd1e03d15258/THUMBNAIL_HOMEPAGE-min.jpg",
  slug: "STUDIO_CITY",
  lat: 34.14583,
  long: -118.41312,
  region: "Southern California/The Valley",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const SUMMIT = {
  id: 150,
  name: "Equinox Summit",
  path: "/clubs/new-jersey/summit",
  address: "68 River Rd",
  city: "Summit",
  state: "NJ",
  zip: "07901",
  country: "US",
  phoneNumber: "(908) 516-5040",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/7jFWk5imz2IN31rvAFBA9U/204d82a5a90601f06db21d81c23db44b/Equinox_ClubPage_OtherLocations_Summit_2300x1533-min.jpg",
  slug: "SUMMIT",
  spa: { phoneNumber: "(908) 516-5040" },
  lat: 40.7320126,
  long: -74.3745011,
  region: "New Jersey",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const THE_LOOP = {
  id: 404,
  name: "Equinox The Loop",
  path: "/clubs/chicago/loop",
  address: "200 West Monroe St",
  city: "Chicago",
  state: "IL",
  zip: "60606",
  country: "US",
  phoneNumber: "(312) 252-3100",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6Vg7wUTAFRmKyupragaVH3/27d30a7746af50bab9dc2720e47d0e41/Equinox_ClubPage_Locations_TheLoop_2300x1533.jpg",
  slug: "THE_LOOP",
  spa: { phoneNumber: "(312) 252-3100" },
  lat: 41.8810364,
  long: -87.6343037,
  region: "Chicago",
  type: "Regular",
  timeZone: timeZones.america.chicago,
};

export const TRIBECA = {
  id: 111,
  name: "Equinox Tribeca",
  path: "/clubs/new-york/downtown/tribeca",
  address: "54 Murray St",
  city: "New York",
  state: "NY",
  zip: "10007",
  country: "US",
  phoneNumber: "(212) 566-6555",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/4gmH6ksoyvF2B75VJtWZPw/9fda83e7dd97b6e810abb2c87e7c73ec/Equinox_ClubPage_OtherLocations_Tribeca_2300x1533-min.jpg",
  slug: "TRIBECA",
  spa: { phoneNumber: "(212) 566-6555" },
  lat: 40.7141901,
  long: -74.0100543,
  region: "New York/Downtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const TYSONS_CORNER = {
  id: 251,
  name: "Equinox Tysons Corner",
  path: "/clubs/washington-dc/tysonscorner",
  address: "8065 Leesburg Pike",
  city: "Vienna",
  state: "VA",
  zip: "22182",
  country: "US",
  phoneNumber: "(703) 790-6193",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/1oPXqcG2NidM9eoVPZ8kkD/0856ab06287b393f946a1e4ba90774f1/Equinox_ClubPage_OtherLocations_TysonsCorner_2300x1533-min.jpg",
  slug: "TYSONS_CORNER",
  spa: { phoneNumber: "(703) 790-6193" },
  lat: 38.9127212,
  long: -77.2247514,
  region: "Washington DC",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const UNION_STREET = {
  id: 723,
  name: "Equinox Union Street",
  path: "/clubs/northern-california/unionstreet",
  address: "2055 Union St",
  city: "San Francisco",
  state: "CA",
  zip: "94123",
  country: "US",
  phoneNumber: "(415) 854-0799",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/67nn9R7QlOORqPWM8CGToN/bb8689a60934a142683db007c1f2ec4b/Equinox_ClubPage_OtherLocations_UnionSt_2300x1533.jpg",
  slug: "UNION_STREET",
  spa: { phoneNumber: "(415) 854-0799" },
  lat: 37.7971209,
  long: -122.4330826,
  region: "Northern California",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const VAN_MISSION = {
  id: 726,
  name: "Equinox Van Mission",
  path: "/clubs/northern-california/vanmission",
  address: "1560 Mission St",
  city: "San Francisco",
  state: "CA",
  zip: "94103",
  country: "US",
  phoneNumber: "(415) 228-5259",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/726rJO27qSS0aLOpQCYK7g/1387893b1cd1a233b7d5c89c28f9f030/thumbnail.jpeg",
  slug: "VAN_MISSION",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 37.7737862,
  long: -122.4205327,
  region: "Northern California",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const WALL_STREET = {
  id: 110,
  name: "Equinox Wall Street",
  path: "/clubs/new-york/downtown/wallstreet",
  address: "14 Wall St",
  city: "New York",
  state: "NY",
  zip: "10005",
  country: "US",
  phoneNumber: "(212) 964-6688",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/5IA4ezFqLkEF23nRCQuJRI/e3dbd2b1a6b832f7ce180a1dcd5267ed/Equinox_ClubPage_OtherLocations_WallSt_2300x1533-min.jpg",
  slug: "WALL_STREET",
  spa: { phoneNumber: "(212) 964-6688" },
  lat: 40.7076346,
  long: -74.0107748,
  region: "New York/Downtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const WESTLAKE_VILLAGE = {
  id: 716,
  name: "Equinox Westlake Village",
  path: "/clubs/southern-california/the-valley/westlakevillage",
  address: "112 S. Lakeview Canyon Rd",
  city: "Thousand Oaks",
  state: "CA",
  zip: "91362",
  country: "US",
  phoneNumber: "(805) 367-3108",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/44dMdFrVVxMk06lQ1576nj/c5f2b34851d94d7bd74855f1cdf72a95/Equinox_ClubPage_OtherLocations_Westlake_2300x1533.jpg",
  slug: "WESTLAKE_VILLAGE",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 34.1569358,
  long: -118.8156861,
  region: "Southern California/The Valley",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const WESTWOOD = {
  id: 704,
  name: "Equinox Westwood",
  path: "/clubs/southern-california/los-angeles/westwood",
  address: "10960 Wilshire Blvd",
  city: "Los Angeles",
  state: "CA",
  zip: "90024",
  country: "US",
  phoneNumber: "(310) 954-8950",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/553tApAr4d8nnC3rPWIdH7/56d12ea676ca0fd0c9e4e8e28b38a417/Equinox_ClubPage_OtherLocations_Westwood_2300x1533-min.jpg",
  slug: "WESTWOOD",
  spa: { phoneNumber: "(310) 954-8950" },
  lat: 34.0578692,
  long: -118.4459193,
  region: "Southern California/Los Angeles",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const WEST_50TH_STREET = {
  id: 107,
  name: "Equinox West 50th Street",
  path: "/clubs/new-york/midtown/west50th",
  address: "1633 Broadway",
  city: "New York",
  state: "NY",
  zip: "10019",
  country: "US",
  phoneNumber: "(212) 541-7000",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/49SjwiAzU6xqq8xtBOgsqd/1d158b4cf4e7e4cfd04115a4204f420f/Equinox_ClubPage_OtherLocations_RockefellerHouse_2300x1533-min.jpg",
  slug: "WEST_50TH_STREET",
  spa: { phoneNumber: "(212) 541-7000" },
  lat: 40.7621098,
  long: -73.9846411,
  region: "New York/Midtown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const WEST_76TH_STREET = {
  id: 121,
  name: "Equinox West 76th Street",
  path: "/clubs/new-york/uptown/west76th",
  address: "344 Amsterdam Ave",
  city: "New York",
  state: "NY",
  zip: "10024",
  country: "US",
  phoneNumber: "(212) 496-2374",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6L1Vh14HYAFGlMV1aDiJPP/81688537c926d826cac817af6ac89330/Equinox_ClubPage_OtherLocations_E74thSt_2300x1533-min.jpg",
  slug: "WEST_76TH_STREET",
  spa: { phoneNumber: "(212) 496-2374" },
  lat: 40.7814129,
  long: -73.9819899,
  region: "New York/Uptown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const WEST_92ND_STREET = {
  id: 103,
  name: "Equinox West 92nd Street",
  path: "/clubs/new-york/uptown/west92nd",
  address: "2465 Broadway",
  city: "New York",
  state: "NY",
  zip: "10025",
  country: "US",
  phoneNumber: "(212) 799-1818",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/3x5tlPl5bLEKQECjMaPnl6/4815085cf7a8fe3ba7dd9f890c0f667f/Equinox_ClubPage_OtherLocations_W92ndS_2300x1533-min.jpg",
  slug: "WEST_92ND_STREET",
  spa: { phoneNumber: "(212) 799-1818" },
  lat: 40.7919865,
  long: -73.9742999,
  region: "New York/Uptown",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const WEST_GEORGIA_STREET = {
  id: 860,
  name: "Equinox West Georgia Street",
  path: "/clubs/canada/vancouver/westgeorgiast",
  address: "1131 West Georgia St",
  city: "Vancouver",
  state: "BC",
  zip: "V6E 4G2",
  country: "CA",
  phoneNumber: "(604) 449-3002",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6VnEYMvqIfG4GKR31PvPPe/b62efa0e64a7cc08a15cbe8b4e7e5197/Equinox_ClubPage_OtherLocations_WestGeorgiaSt_2300x1533-min.jpg",
  slug: "WEST_GEORGIA_STREET",
  lat: 49.2853051,
  long: -123.1253383,
  region: "Canada/Vancouver",
  type: "Regular",
  timeZone: timeZones.america.vancouver,
};

export const WEST_HOLLYWOOD = {
  id: 702,
  name: "Equinox West Hollywood",
  path: "/clubs/southern-california/los-angeles/westhollywood",
  address: "8590 Sunset Blvd",
  city: "West Hollywood",
  state: "CA",
  zip: "90069",
  country: "US",
  phoneNumber: "(310) 289-1900",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/6KgWuSANUADiS0Hkm2FtNX/cd289e290d0399a9ba544e8a6ce2ae6b/Equinox_ClubPage_OtherLocations_WestHollywood_2300x1533.jpg",
  slug: "WEST_HOLLYWOOD",
  spa: { phoneNumber: "(310) 657-4372" },
  lat: 34.0922597,
  long: -118.3787753,
  region: "Southern California/Los Angeles",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const WILLIAMSBURG = {
  id: 137,
  name: "Equinox Williamsburg",
  path: "/clubs/new-york/brooklyn/williamsburg",
  address: "246 Bedford Ave",
  city: "Brooklyn",
  state: "NY",
  zip: "11211",
  country: "US",
  phoneNumber: "(718) 522-5703",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/3DKAcpqIchCkZEsav7LwgK/380d7c7092ff02b4bee6cbbed1c857de/Equinox_ClubPage_OtherLocations_Williamsburg_2300x1533-min.jpg",
  slug: "WILLIAMSBURG",
  spa: { phoneNumber: "(718) 522-5703" },
  lat: 40.715846,
  long: -73.959883,
  region: "New York/Brooklyn",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const WISCONSIN_AVE = {
  id: 256,
  name: "Equinox Wisconsin Ave",
  path: "/clubs/washington-dc/wisconsinave",
  address: "16 Ridge Square NW",
  city: "Washington",
  state: "DC",
  zip: "20016",
  country: "US",
  phoneNumber: "(202) 249-4935",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/3UTrh2YFmeg6o5OgebaV1a/ce3e307c6226bbda02bc1835faf1d6d6/WISCONSIN_AVE_WEB_IMAGES_03-min.jpg",
  slug: "WISCONSIN_AVE",
  spa: { phoneNumber: "(202) 249-4935" },
  lat: 38.9406132,
  long: -77.0780348,
  region: "Washington DC",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const WOODBURY = {
  id: 142,
  name: "Equinox Woodbury",
  path: "/clubs/new-york/long-island/woodbury",
  address: "7550 Jericho Turnpike",
  city: "Woodbury",
  state: "NY",
  zip: "11797",
  country: "US",
  phoneNumber: "(516) 714-8100",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/rrO7bYGYx4uUZpqcTpg9y/832c893e2d7e203f2b8ea9fa86584ffc/Equinox_ClubPage_OtherLocations_Woodbury_2300x1533-min.jpg",
  slug: "WOODBURY",
  spa: { phoneNumber: "(516) 714-8100" },
  lat: 40.811409,
  long: -73.489107,
  region: "New York/Long Island",
  type: "Regular",
  timeZone: timeZones.america.newYork,
};

export const WOODLAND_HILLS = {
  id: 707,
  name: "Equinox Woodland Hills",
  path: "/clubs/southern-california/the-valley/woodlandhills",
  address: "21530 Oxnard St",
  city: "Woodland Hills",
  state: "CA",
  zip: "91367",
  country: "US",
  phoneNumber: "(818) 887-6450",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/7sn1LSkqFUpj8FtaZ7ezIu/04d9f34557560bf30208e44af423dd52/Equinox_ClubPage_OtherLocations_WoodlandHills_2300x1533-min.jpg",
  slug: "WOODLAND_HILLS",
  spa: { phoneNumber: "(818) 887-6450" },
  lat: 34.1788729,
  long: -118.5987926,
  region: "Southern California/The Valley",
  type: "Regular",
  timeZone: timeZones.america.losAngeles,
};

export const YORKVILLE = {
  id: 854,
  name: "Equinox Yorkville",
  path: "/clubs/canada/toronto/yorkville",
  address: "55 Avenue Rd",
  city: "Toronto",
  state: "ON",
  zip: "M5R 3L2",
  country: "CA",
  phoneNumber: "(416) 961-8400",
  image:
    "//images.ctfassets.net/drib7o8rcbyf/13rUch5LWMFacuElARc5xm/7dbfce11fec5ede1889a0f160bc37fee/Equinox_ClubPage_OtherLocations_Yorkville_2300x1533-min.jpg",
  slug: "YORKVILLE",
  spa: { phoneNumber: "(866) 332-6549" },
  lat: 43.671654,
  long: -79.3946025,
  region: "Canada/Toronto",
  type: "Regular",
  timeZone: timeZones.america.toronto,
};

const CLUBS = [
  ANTHEM_ROW,
  ARMONK,
  AUSTIN,
  AVENTURA,
  BAY_STREET,
  BEALE_STREET,
  BERKELEY,
  BETHESDA,
  BEVERLY_HILLS,
  BISHOPSGATE,
  BLOOMFIELD_HILLS,
  BOND_STREET,
  BRICKELL,
  BRICKELL_HEIGHTS,
  BROOKFIELD_PLACE,
  BROOKLYN_HEIGHTS,
  BRYANT_PARK,
  CENTURY_CITY,
  CHESTNUT_HILL,
  COLUMBUS_CIRCLE,
  CORAL_GABLES,
  CULVER_CITY,
  DARIEN,
  DARTMOUTH_STREET,
  DOMINO,
  DOWNTOWN_LA,
  DUMBO,
  EAST_43RD_STREET,
  EAST_44TH_STREET,
  EAST_53RD_STREET,
  EAST_54TH_STREET,
  EAST_61ST_STREET,
  EAST_63RD_STREET,
  EAST_74TH_STREET,
  EAST_85TH_STREET,
  EAST_92ND_STREET,
  ENCINO,
  EQUINOX_X_HAMPTONS,
  E_AT_COLUMBUS_CIRCLE,
  E_AT_GREENWICH_CT,
  E_AT_HUDSON_YARDS,
  E_MADISON_AVENUE,
  E_SAN_FRANCISCO,
  E_ST_JAMESS,
  FLATIRON,
  FRANKLIN_STREET,
  GLENDALE,
  GOLD_COAST,
  GRAMERCY,
  GREAT_NECK,
  GREENWICH_AVENUE,
  GREENWICH_CT,
  HIGHLAND_PARK_DALLAS,
  HIGH_LINE,
  HOLLYWOOD,
  HUDSON_YARDS,
  HUNTINGTON_BEACH,
  KENSINGTON,
  LA_COSTA,
  LINCOLN_COMMON,
  LINCOLN_PARK,
  MAMARONECK,
  MARINA_DEL_REY,
  MIRACLE_MILE,
  NEWPORT_BEACH,
  NOMAD,
  ORCHARD_STREET,
  PACIFIC_PALISADES,
  PALOS_VERDES,
  PALO_ALTO,
  PARAMUS,
  PARK_AVENUE,
  PASADENA,
  PINE_STREET,
  PLANO,
  PRESTON_HOLLOW,
  PRINTING_HOUSE,
  RAINIER_SQUARE,
  RITTENHOUSE,
  RIVER_OAKS,
  ROCKEFELLER_CENTER,
  ROSLYN,
  SANTA_MONICA,
  SAN_MATEO,
  SAN_RAMON,
  SCARSDALE,
  SEAPORT,
  SOHO,
  SOUTHPORT,
  SOUTH_BAY,
  SOUTH_BEACH,
  SPORTS_CLUB_BOSTON,
  SPORTS_CLUB_LOS_ANGELES,
  SPORTS_CLUB_NEW_YORK,
  SPORTS_CLUB_ORANGE_COUNTY,
  SPORTS_CLUB_SAN_FRANCISCO,
  SPORTS_CLUB_WASHINGTON_DC,
  STUDIO_CITY,
  SUMMIT,
  THE_LOOP,
  TRIBECA,
  TYSONS_CORNER,
  UNION_STREET,
  VAN_MISSION,
  WALL_STREET,
  WESTLAKE_VILLAGE,
  WESTWOOD,
  WEST_50TH_STREET,
  WEST_76TH_STREET,
  WEST_92ND_STREET,
  WEST_GEORGIA_STREET,
  WEST_HOLLYWOOD,
  WILLIAMSBURG,
  WISCONSIN_AVE,
  WOODBURY,
  WOODLAND_HILLS,
  YORKVILLE,
];

const byId = {};

CLUBS.forEach((club) => {
  byId[club.id] = club;
});

export { byId };

const byRegion = {};

byRegion["Boston"] = [
  CHESTNUT_HILL,
  DARTMOUTH_STREET,
  FRANKLIN_STREET,
  SEAPORT,
  SPORTS_CLUB_BOSTON,
];

byRegion["Canada/Toronto"] = [BAY_STREET, YORKVILLE];

byRegion["Canada/Vancouver"] = [WEST_GEORGIA_STREET];

byRegion["Chicago"] = [GOLD_COAST, LINCOLN_COMMON, LINCOLN_PARK, THE_LOOP];

byRegion["Connecticut"] = [DARIEN, E_AT_GREENWICH_CT, GREENWICH_CT, SOUTHPORT];

byRegion["Florida"] = [
  AVENTURA,
  BRICKELL,
  BRICKELL_HEIGHTS,
  CORAL_GABLES,
  SOUTH_BEACH,
];

byRegion["London"] = [BISHOPSGATE, E_ST_JAMESS, KENSINGTON];

byRegion["Michigan"] = [BLOOMFIELD_HILLS];

byRegion["New Jersey"] = [PARAMUS, SUMMIT];

byRegion["New York/Brooklyn"] = [BROOKLYN_HEIGHTS, DOMINO, DUMBO, WILLIAMSBURG];

byRegion["New York/Downtown"] = [
  BOND_STREET,
  BROOKFIELD_PLACE,
  FLATIRON,
  GRAMERCY,
  GREENWICH_AVENUE,
  HIGH_LINE,
  NOMAD,
  ORCHARD_STREET,
  PRINTING_HOUSE,
  SOHO,
  TRIBECA,
  WALL_STREET,
];

byRegion["New York/Long Island"] = [
  EQUINOX_X_HAMPTONS,
  GREAT_NECK,
  ROSLYN,
  WOODBURY,
];

byRegion["New York/Midtown"] = [
  BRYANT_PARK,
  EAST_43RD_STREET,
  EAST_44TH_STREET,
  EAST_53RD_STREET,
  EAST_54TH_STREET,
  E_AT_HUDSON_YARDS,
  HUDSON_YARDS,
  PARK_AVENUE,
  ROCKEFELLER_CENTER,
  WEST_50TH_STREET,
];

byRegion["New York/Uptown"] = [
  COLUMBUS_CIRCLE,
  EAST_61ST_STREET,
  EAST_63RD_STREET,
  EAST_74TH_STREET,
  EAST_85TH_STREET,
  EAST_92ND_STREET,
  E_AT_COLUMBUS_CIRCLE,
  E_MADISON_AVENUE,
  SPORTS_CLUB_NEW_YORK,
  WEST_76TH_STREET,
  WEST_92ND_STREET,
];

byRegion["New York/Westchester"] = [ARMONK, MAMARONECK, SCARSDALE];

byRegion["Northern California"] = [
  BEALE_STREET,
  BERKELEY,
  E_SAN_FRANCISCO,
  PALO_ALTO,
  PINE_STREET,
  SAN_MATEO,
  SAN_RAMON,
  SPORTS_CLUB_SAN_FRANCISCO,
  UNION_STREET,
  VAN_MISSION,
];

byRegion["Pennsylvania/Philadelphia"] = [RITTENHOUSE];

byRegion["Southern California/Los Angeles"] = [
  BEVERLY_HILLS,
  CENTURY_CITY,
  CULVER_CITY,
  DOWNTOWN_LA,
  HOLLYWOOD,
  MIRACLE_MILE,
  PACIFIC_PALISADES,
  SANTA_MONICA,
  SPORTS_CLUB_LOS_ANGELES,
  WESTWOOD,
  WEST_HOLLYWOOD,
];

byRegion["Southern California/Orange County"] = [
  HUNTINGTON_BEACH,
  NEWPORT_BEACH,
  SPORTS_CLUB_ORANGE_COUNTY,
];

byRegion["Southern California/San Diego"] = [LA_COSTA];

byRegion["Southern California/South Bay"] = [
  MARINA_DEL_REY,
  PALOS_VERDES,
  SOUTH_BAY,
];

byRegion["Southern California/The Valley"] = [
  ENCINO,
  GLENDALE,
  PASADENA,
  STUDIO_CITY,
  WESTLAKE_VILLAGE,
  WOODLAND_HILLS,
];

byRegion["Texas"] = [AUSTIN, HIGHLAND_PARK_DALLAS, PLANO, RIVER_OAKS];

byRegion["Washington DC"] = [
  ANTHEM_ROW,
  BETHESDA,
  SPORTS_CLUB_WASHINGTON_DC,
  TYSONS_CORNER,
  WISCONSIN_AVE,
];

byRegion["Washington/Seattle"] = [RAINIER_SQUARE];

export { byRegion };

export default CLUBS;

//clubs in API but not Contentful:
// 998A: Equinox Online US Adyen
// 9900: The Box Shops Pop-up
// 940: Equinox +
// 950: Equinox Amex
// 2000: The Amex Spa Lounge at JFK
